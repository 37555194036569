import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="work-feature"
export default class extends Controller {

  connect() { // used to show complete bth when audio is completed
    let audioElement = this.data.scope.element;
    audioElement.addEventListener("ended", (event) => {
      document.querySelector('.complete-button').classList.remove('hidden');
    });
  }

  validateComment() {
    let comment = this.data.scope.element;
    this.autoExpandHeight(comment);
    let lenght = parseInt(this.data.scope.element.dataset.length) || 50;
    let message = this.data.scope.element.dataset.message || "Please provide some more detail to help us improve.";
    if (comment.value.length < lenght) {
      comment.setCustomValidity(message)
    }
    else{
      comment.setCustomValidity('')
    }
  }

  autoExpandHeight(element){
    var heightLimit = 200;
    element.style.height = "";
    element.style.height = Math.min(element.scrollHeight + 2, heightLimit) + "px";
  }

  onRadioChange(){
    if(this.data.scope.element.value === '0'){
      document.querySelector('.no-selection').classList.remove('hidden');
      document.querySelector('.yes-selection').classList.add('hidden');
    }
    else {
      document.querySelector('.yes-selection').classList.remove('hidden');
      document.querySelector('.no-selection').classList.add('hidden');
    }
  }

  // add subject list, using add row button
  addSubject() {
    const newSubject = document.createElement("div");
    newSubject.innerHTML = this.template();
    this.element.insertAdjacentElement("beforebegin", newSubject);
  }

  template() {
    const current_nodes = this.element.firstElementChild.dataset.nodes || 3;
    const subjectCount = parseInt(current_nodes) + 1;
    this.element.firstElementChild.dataset.nodes = subjectCount;

    return `
      <div class="mt-4 flex gap-2 mb-4">
        <label for="q${subjectCount}" class="text-base font-normal text-black mt-2">${subjectCount}.</label>
        <input type="text" name="q${subjectCount}" id="q${subjectCount}" required="required" data-controller="work-feature" data-action="work-feature#validateComment" data-length="2" data-message="Ensure that field contains a minimum of 2 characters.">
      </div>
    `;
  }

}
